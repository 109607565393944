/*@import '@palette.tools/tailwind/globals.css';*/
@import url('../../../tailwind/globals.css');

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #ffffff30 #ffffff00; /* Lighter color: 20 instead of 10 */
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff30; /* Lighter color: 20 instead of 10 */
    border-radius: 5px;
    border: 2px solid #ffffff00;
  }

/* Hide Scrollbar */

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

.hide-scrollbar {
  scrollbar-width: none !important;
  scrollbar-color: transparent transparent !important;
}

@font-face {
  font-family: 'Degular';
  src: url('/fonts/Degular-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Degular';
  src: url('/fonts/Degular-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Degular';
  src: url('/fonts/Degular-Semibold.woff2') format('woff2');
  font-weight: 600; /* You can adjust the font weight as needed */
  font-style: normal;
}

@font-face {
  font-family: 'DegularText';
  src: url('/fonts/Degular_Text-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DegularText';
  src: url('/fonts/Degular_Text-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DegularText';
  src: url('/fonts/Degular_Text-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DegularDisplay';
  src: url('/fonts/Degular_Display-Bold.woff2') format('woff2');
  font-weight: bold; /* You can adjust the font weight as needed */
  font-style: normal;
}